import React, { useEffect, useState } from "react";
import { Alert, AlertIcon, Button, Box } from "@chakra-ui/react";

const UpdateNotification = () => {
  const [showReload, setShowReload] = useState(false);

  useEffect(() => {
    if (!("serviceWorker" in navigator)) return;

    let updateCheckInterval: number;
    const CHECK_INTERVAL = 5 * 60 * 1000; // 5 minutes

    const checkForUpdates = async (registration: ServiceWorkerRegistration) => {
      try {
        const currentVersion = registration.active?.scriptURL;
        const response = await fetch(registration.active?.scriptURL || "", {
          cache: "no-cache",
        });
        const newVersion = response.url;
        return currentVersion !== newVersion;
      } catch (error) {
        console.error("Update check failed:", error);
        return false;
      }
    };

    navigator.serviceWorker.ready.then((registration) => {
      updateCheckInterval = window.setInterval(async () => {
        const hasUpdate = await checkForUpdates(registration);
        if (hasUpdate) setShowReload(true);
      }, CHECK_INTERVAL);

      registration.addEventListener("updatefound", () => {
        const newWorker = registration.installing;
        if (!newWorker) return;

        newWorker.addEventListener("statechange", () => {
          if (
            newWorker.state === "installed" &&
            navigator.serviceWorker.controller
          ) {
            setShowReload(true);
          }
        });
      });

      const handleVisibilityChange = async () => {
        if (document.visibilityState === "visible" && !showReload) {
          const hasUpdate = await checkForUpdates(registration);
          if (hasUpdate) setShowReload(true);
        }
      };

      document.addEventListener("visibilitychange", handleVisibilityChange);
      checkForUpdates(registration);

      return () => {
        clearInterval(updateCheckInterval);
        document.removeEventListener(
          "visibilitychange",
          handleVisibilityChange,
        );
      };
    });
  }, [showReload]);

  const handleReload = () => {
    window.location.reload();
  };

  if (!showReload) return null;

  return (
    <Box position="fixed" bottom={4} right={4} zIndex={1000}>
      <Alert status="info" borderRadius="md">
        <AlertIcon />
        New version available!
        <Button ml={4} colorScheme="blue" size="sm" onClick={handleReload}>
          Update & Reload
        </Button>
      </Alert>
    </Box>
  );
};

export default UpdateNotification;
