import React from "react";
import { Box, Tooltip, Icon, useColorModeValue } from "@chakra-ui/react";
import { MdCloud, MdLaptopMac } from "react-icons/md";
import useAppMode from "../store/appMode/appModeState";
import useI18n from "../hooks/useI18n";

export interface TTSIndicatorProps {
  isUsingCloud: boolean;
}

const TTSIndicator: React.FC<TTSIndicatorProps> = ({ isUsingCloud }) => {
  const { t } = useI18n("common");
  const tooltipLabel = isUsingCloud
    ? t("tts.service_cloud")
    : t("tts.service_device");

  const { isEditMode } = useAppMode();

  // Determine the color based on edit mode and theme mode
  const iconColor = isUsingCloud ? "blue.400" : "yellow.400";

  return (
    <Tooltip label={tooltipLabel} placement="bottom">
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        transition="color 0.2s ease-in-out"
        color={iconColor}
        _hover={{ color: useColorModeValue("gray.800", "gray.300") }}
      >
        <Icon as={isUsingCloud ? MdCloud : MdLaptopMac} boxSize="18px" />
      </Box>
    </Tooltip>
  );
};

export default TTSIndicator;
